import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import _debounce from 'lodash/debounce';
import { isXsMobile, isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import ApiService from 'src/util/api';
import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { Device } from '../../src/lib/universal/utils/devices';
import NextGuestExperiencePage from '../../src/cards/pages/GuestExperiencePage_next';
import { setCookie, getCookie } from '../../src/util/storage';

const GuestExperiencePage = ({ digitalGuestExperience, isInitialXsMobile, isInitialDesktop }) => {
  const [windowIsReady, setWindowIsReady] = useState();
  const [isXsMobileSize, setIsXsMobileSize] = useState(isInitialXsMobile);
  const [isDesktopSize, setIsDesktopSize] = useState(isInitialDesktop);

  useEffect(() => {
    setIsXsMobileSize(isXsMobile());
    setIsDesktopSize(isDesktop());
    const handleResize = _debounce(() => {
      setIsXsMobileSize(isXsMobile());
      setIsDesktopSize(isDesktop());
      setWindowIsReady(!!window.innerWidth);
    }, 1000);
    window.addEventListener('resize', handleResize);
    // Need to trigger the resize event to get the correct window.innerWidth value on load
    window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffectOnce(() => {
    if (digitalGuestExperience && typeof window !== 'undefined') {
      const { uuid } = digitalGuestExperience;
      const dstdCookie = getCookie('hasVisitedDstd');
      if (dstdCookie !== uuid) {
        trackEvent('Product Viewed', {
          customization_project_uuid: uuid,
          business_unit: 'PAPER',
          component: 'DIGITAL_SAVE_THE_DATES',
          location: 'GUEST_FACING_DSTD_PUBLISHED',
        });
        setCookie('hasVisitedDstd', `${uuid}`);
      }
    }
  }, []);

  return (
    <>
      <Head>
        <meta property="og:title" content="Save the date for our wedding" />
        <meta property="og:image" content="https://www.zola.com/images/envelope-preview.png" />
        <meta
          property="og:image:secure_url"
          itemProp="image"
          content="https://www.zola.com/images/envelope-preview.png"
        />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <link itemProp="thumbnailUrl" href="https://www.zola.com/images/envelope-preview.png" />
      </Head>
      <NextGuestExperiencePage
        digitalGuestExperience={digitalGuestExperience}
        isXsMobile={isXsMobileSize}
        isDesktop={isDesktopSize}
        windowIsReady={windowIsReady}
      />
    </>
  );
};

export async function getServerSideProps(context) {
  const { req } = context;

  const userAgent = req.headers['user-agent'];
  const isInitialXsMobile = new Device(userAgent).isXsMobile();
  const isInitialDesktop = new Device(userAgent).isDesktop();

  const { uniqueUrlSlug } = req.params;

  let props;

  try {
    const digitalGuestExperience = await ApiService.get(
      `/web-api/v2/card-project/digital/published/${uniqueUrlSlug}`
    );

    props = {
      digitalGuestExperience,
    };
  } catch (e) {
    props = {
      digitalGuestExperience: null,
    };
  }

  return {
    props: {
      ...props,
      isInitialXsMobile,
      isInitialDesktop,
    },
  };
}

export default GuestExperiencePage;
