import React from 'react';
import type {
  WProjectCustomizationElementView,
  WProjectCustomizationFullView,
  WProjectCustomizationPageView,
} from '@zola/svc-web-api-ts-client';
import SharePreviewGuest from 'cards/components/Customization/DigitalSteps/Share/SharePreviewGuest';

type DigitalGuestExperience = {
  uuid: string;
  customizations: WProjectCustomizationFullView[];
  wedding_website_slug: string;
  super_link_slug: string;
  owner_first_name: string;
  owner_last_name: string;
  owner_wedding_role: string;
  partner_first_name: string;
  partner_last_name: string;
  partner_wedding_role: string;
};

type GuestExperiencePageProps = {
  digitalGuestExperience: DigitalGuestExperience;
  isXsMobile: boolean;
  isDesktop: boolean;
  windowIsReady: boolean;
};

const GuestExperiencePage = (props: GuestExperiencePageProps): JSX.Element => {
  const { digitalGuestExperience, isXsMobile, isDesktop, windowIsReady } = props;

  const getElementsFromPage = (
    customization: WProjectCustomizationFullView,
    pageNumber: number
  ): WProjectCustomizationElementView[] => {
    const page =
      (customization.pages && customization.pages[pageNumber]) ||
      ({} as WProjectCustomizationPageView);

    const { elements } = page;
    return elements || [];
  };

  const getPageForCustomization = (
    customization: WProjectCustomizationFullView,
    pageNumber: number
  ): WProjectCustomizationPageView =>
    (customization.pages && customization.pages[pageNumber]) ||
    ({} as WProjectCustomizationPageView);

  const displayGuestExperience = () => {
    // If the DSTD exists, destructure values and check if it's published
    if (digitalGuestExperience) {
      const {
        customizations,
        super_link_slug: superLinkSlug,
        wedding_website_slug: weddingWebsiteSlug,
        owner_first_name: ownerFirstName,
        partner_first_name: partnerFirstName,
      } = digitalGuestExperience;
      const guestExperienceCustomization = customizations[0];
      const { digital_details: digitalDetails } = guestExperienceCustomization;
      const { details, published_at: publishedAt } = digitalDetails || {};

      const {
        animation,
        background_color: backgroundColor,
        show_superlink: showSuperLink,
        show_wedding_website: showWeddingWebsite,
        opacity,
      } = details || {};

      const ownerAndPartnerNames = `${ownerFirstName} & ${partnerFirstName}`;

      const pageToDisplay = 0;

      // If the DSTD is published, display the preview component
      if (publishedAt) {
        return (
          windowIsReady && (
            <SharePreviewGuest
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnimationEnum | undefined' is not assignable...
              animation={animation}
              customizations={customizations}
              opacity={opacity}
              backgroundColor={backgroundColor}
              showSuperLink={showSuperLink}
              superLinkSlug={superLinkSlug}
              showWeddingWebsite={showWeddingWebsite}
              weddingWebsiteSlug={weddingWebsiteSlug}
              elements={getElementsFromPage(guestExperienceCustomization, pageToDisplay)}
              page={getPageForCustomization(guestExperienceCustomization, pageToDisplay)}
              ownerAndPartnerNames={ownerAndPartnerNames}
              isXsMobile={isXsMobile}
              isDesktop={isDesktop}
            />
          )
        );
      }

      if (weddingWebsiteSlug && typeof window !== 'undefined') {
        window.location.href = `/wedding/${weddingWebsiteSlug}?save_the_date_unavailable=true`;
      }

      if (!weddingWebsiteSlug && typeof window !== 'undefined') {
        window.location.href = '/wedding-planning/digital-save-the-date-unavailable';
      }
    }

    if (typeof window !== 'undefined') {
      window.location.href = '/wedding-planning/digital-save-the-date-unavailable';
    }

    return null;
  };

  return (
    <>
      <div>{displayGuestExperience()}</div>
    </>
  );
};

export default GuestExperiencePage;
